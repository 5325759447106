import React from "react";
import { Link } from "gatsby";

import { useForm } from "@formspree/react";

import { CheckCircleIcon, XIcon } from "@heroicons/react/solid";

const Contact = () => {
  const [state, handleSubmit] = useForm("xayvdrqe");

  if (state.succeeded) {
    return (
      <>
        <section
          className="text-gray-600 py-8 px-4 lg:py-0 body-font relative bg-e-blue"
          id="contact"
        >
          <div className="container  mx-auto flex sm:flex-nowrap flex-wrap">
            <div className="hidden md:flex lg:w-1/2 md:w-1/2 h-[800px] bg-[url('https://images.ctfassets.net/agskyiykp2ye/2hnc86qLzs37JOj5LnLPbs/549266d089c593e5cf6abca9d6364428/amy-hirschi-uwpo02K55zw-unsplash.jpg')] bg-cover bg-no-repeat -lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
              <div className="bg-white relative flex flex-wrap py-6 shadow-md">
                <div className="lg:w-1/2 px-6">
                  <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                    ADDRESS
                  </h2>
                  <p className="mt-1">
                    297 Elizabeth Street, North Hobart TAS 7000
                  </p>
                  <p className="mt-1">
                    Ground Floor, 470 St Kilda Road, Melbourne VIC 3004
                  </p>
                </div>
                <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
                  <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                    EMAIL
                  </h2>
                  <a
                    className="text-e-blue leading-relaxed"
                    href="mailto:info@edgelegal.com.au"
                  >
                    info@edgelegal.com.au
                  </a>
                  <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">
                    PHONE
                  </h2>
                  <a href="tel:1300773343" className="leading-relaxed">
                    1300 77 EDGE
                  </a>
                </div>
              </div>
            </div>
            <form
              onSubmit={handleSubmit}
              className="lg:w-1/3 md:w-1/2 flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0 bg-e-blue justify-center"
            >
              <h2 className="text-white text-2xl mb-1 font-medium title-font">
                Quick Contact
              </h2>
              <p className="leading-relaxed mb-5 text-white">
                Send us a brief message and we will respond to you.
              </p>
              <div className="relative mb-4">
                <label htmlFor="name" className="leading-7 text-sm text-white">
                  Name
                </label>
                <input
                  id="name"
                  type="name"
                  name="name"
                  invalidtext="Invalid Name"
                  labeltext="Your Name"
                  placeholder="Name"
                  className="w-full bg-e-blue  border border-gray-300 focus:border-e-blue focus:ring-2 focus:ring-indigo-200 text-white outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
              <div className="relative mb-4">
                <label htmlFor="email" className="leading-7 text-sm text-white">
                  Email
                </label>
                <input
                  id="emailfooter"
                  type="email"
                  name="email"
                  invalidtext="Invalid Email"
                  labeltext="Your Email"
                  placeholder="Email"
                  className="w-full bg-e-blue  border border-gray-300 focus:border-e-blue focus:ring-2 focus:ring-indigo-200 text-white outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
              <div className="relative mb-4">
                <label htmlFor="phone" className="leading-7 text-sm text-white">
                  Phone
                </label>
                <input
                  id="phonefooter"
                  type="phone"
                  name="phone"
                  required
                  invalidtext="Invalid Number"
                  labeltext="Your Number"
                  placeholder="Phone Number"
                  className="w-full bg-e-blue  border border-gray-300 focus:border-e-blue focus:ring-2 focus:ring-indigo-200 text-white outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
              <div className="relative mb-4">
                <label
                  htmlFor="company"
                  className="leading-7 text-sm text-white"
                >
                  Company (optional)
                </label>
                <input
                  id="emailfooter"
                  type="company"
                  name="company"
                  required
                  invalidtext="Invalid Company"
                  labeltext="Your Company"
                  placeholder="Company"
                  className="w-full bg-e-blue  border border-gray-300 focus:border-e-blue focus:ring-2 focus:ring-indigo-200 text-white outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
              <div className="relative mb-4">
                <label
                  htmlFor="message"
                  className="leading-7 text-sm text-white"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  invalidtext="Invalid error message."
                  labeltext="Your Message"
                  placeholder="Message"
                  className="w-full bg-e-blue  border border-gray-300 focus:border-e-blue focus:ring-2 focus:ring-indigo-200 h-32 text-white outline-none py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                ></textarea>
              </div>
              <button
                type="submit"
                disabled={state.submitting}
                className="text-black bg-e-yellow border-0 py-2 px-6 focus:outline-none hover:bg-e-yellow-hover  text-lg"
              >
                Submit
              </button>
              <p className="text-xs text-white mt-3">
                Read our{" "}
                <Link to="/terms" className="underline">
                  Privacy Policy.
                </Link>
              </p>

              <div className=" bg-green-50 p-4 mt-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-5 w-5 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-green-800">
                      Your message has been sent.
                    </p>
                  </div>
                  <div className="ml-auto pl-3">
                    <div className="-mx-1.5 -my-1.5">
                      <button
                        onClick={() => window.location.reload(false)}
                        type="button"
                        className="inline-flex bg-green-50  p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                      >
                        <span className="sr-only">Dismiss</span>
                        <XIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
      </>
    );
  }

  return (
    <>
      <section
        className="text-gray-600 py-8 px-4 lg:py-0 body-font relative bg-e-blue"
        id="contact"
      >
        <div className="container  mx-auto flex sm:flex-nowrap flex-wrap">
          <div className="hidden md:flex lg:w-1/2 md:w-1/2 h-[800px] bg-[url('https://images.ctfassets.net/agskyiykp2ye/2hnc86qLzs37JOj5LnLPbs/549266d089c593e5cf6abca9d6364428/amy-hirschi-uwpo02K55zw-unsplash.jpg')] bg-cover bg-no-repeat -lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
            <div className="bg-white relative flex flex-wrap py-6 shadow-md">
              <div className="lg:w-1/2 px-6">
                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                  ADDRESS
                </h2>
                <p className="mt-1">
                  190 Macquarie Street, Hobart TAS 7000
                </p>
                <p className="mt-1">
                  Ground Floor, 470 St Kilda Road, Melbourne VIC 3004
                </p>
              </div>
              <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                  EMAIL
                </h2>
                <a
                  className="text-e-blue leading-relaxed"
                  href="mailto:info@edgelegal.com.au"
                >
                  info@edgelegal.com.au
                </a>
                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">
                  PHONE
                </h2>
                <a href="tel:1300773343" className="leading-relaxed">
                  1300 77 EDGE
                </a>
              </div>
            </div>
          </div>
          <form
            onSubmit={handleSubmit}
            className="lg:w-1/3 md:w-1/2 flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0 bg-e-blue justify-center"
          >
            <h2 className="text-white text-2xl mb-1 font-medium title-font">
              Quick Contact
            </h2>
            <p className="leading-relaxed mb-5 text-white">
              Send us a brief message and we will respond to you.
            </p>
            <div className="relative mb-4">
              <label htmlFor="name" className="leading-7 text-sm text-white">
                Name
              </label>
              <input
                id="name"
                type="name"
                name="name"
                required
                invalidtext="Invalid Name"
                labeltext="Your Name"
                placeholder="Name"
                className="w-full bg-e-blue  border border-gray-300 focus:border-e-blue focus:ring-2 focus:ring-indigo-200 text-white outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <div className="flex justify-between gap-4">
              <div className="relative mb-4">
                <label htmlFor="email" className="leading-7 text-sm text-white">
                  Email
                </label>
                <input
                  id="emailfooter"
                  type="email"
                  name="email"
                  required
                  invalidtext="Invalid Email"
                  labeltext="Your Email"
                  placeholder="Email"
                  className="w-full bg-e-blue  border border-gray-300 focus:border-e-blue focus:ring-2 focus:ring-indigo-200 text-white outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
              <div className="relative mb-4">
                <label htmlFor="phone" className="leading-7 text-sm text-white">
                  Phone
                </label>
                <input
                  id="phonefooter"
                  type="phone"
                  name="phone"
                  required
                  invalidtext="Invalid Number"
                  labeltext="Your Number"
                  placeholder="Phone Number"
                  className="w-full bg-e-blue  border border-gray-300 focus:border-e-blue focus:ring-2 focus:ring-indigo-200 text-white outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
            </div>

            <div className="relative mb-4">
              <label htmlFor="company" className="leading-7 text-sm text-white">
                Company (optional)
              </label>
              <input
                id="company"
                type="company"
                name="company"
                invalidtext="Invalid Company"
                labeltext="Your Company"
                placeholder="Company"
                className="w-full bg-e-blue  border border-gray-300 focus:border-e-blue focus:ring-2 focus:ring-indigo-200 text-white outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <div className="relative mb-4">
              <label htmlFor="message" className="leading-7 text-sm text-white">
                Message
              </label>
              <textarea
                id="message"
                name="message"
                invalidtext="Invalid error message."
                labeltext="Your Message"
                placeholder="Message"
                className="w-full bg-e-blue  border border-gray-300 focus:border-e-blue focus:ring-2 focus:ring-indigo-200 h-32 text-white outline-none py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
              ></textarea>
            </div>
            <button
              type="submit"
              disabled={state.submitting}
              className="text-black bg-e-yellow border-0 py-2 px-6 focus:outline-none hover:bg-e-yellow-hover  text-lg"
            >
              Submit
            </button>
            <p className="text-xs text-white mt-3">
              We care about the protection of your data. Read our{" "}
              <Link to="/terms" className="underline">
                Privacy Policy.
              </Link>
            </p>
          </form>
        </div>
      </section>
    </>
  );
};

export default Contact;
