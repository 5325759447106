import React, { useState } from "react";

import { ArrowSmLeftIcon, ArrowSmRightIcon } from "@heroicons/react/outline";

const testimonials = [
  {
    image:
      "https://tassalgroup.com.au/wp-content/uploads/sites/2/2020/06/tassalGROUP-logo.png",
    company: "Tassal",
    quote:
      "Our business outcomes have been enhanced as a result of our ongoing partnership, which we look forward to continuing into the future. It is without any hesitation that I would recommend the team.",
    author: "Kaylene Little",
    title: "Head of People, Culture and QA",
  },
  {
    image:
      "https://www.huonaqua.com.au/wp-content/themes/theme/static/images/huon_aquaculture_logo.png",
    company: "Huon Aquaculture",
    quote:
      "Edge Legal provides effective outcomes for us. They understand our business, work seamlessly with our team and within budget.",
    author: "Tony Baker",
    title: "General Manager, People, Safety and Culture",
  },
  {
    image:
      "https://www.lgat.tas.gov.au/__data/assets/image/0017/324620/LGAT_Desktop_Logo.png",
    company: "LGAT",
    quote:
      "Their client focus and strategic approach to supporting us during this work has created a strong partnership rather than a transactional relationship.",
    author: "Dr Katrina Stephenson",
    title: "Chief Executive Officer",
  },
  {
    image:
      "https://www.treecrop.com.au/media/news/image/costa-tag-logo-green3x.png",
    company: "Costa Food Group",
    quote:
      "Edge Legal works closely with our HR team and directly with Line leaders as part of the team when we make the decision. They also support the implementation of the decision and if need be, advocates in the various jurisdictions. I value their advice and judgement.",
    author: "Carl Phillips",
    title: "GM Human Resources",
  },
  {
    image:
      "https://cottonongroup.com.au/wp-content/themes/cotton-on-group_2020/resources/images/cottonon_group.svg",
    company: "Cotton On Group",
    quote:
      "Edge Legal is an integral partner to our fast-paced, complex and entrepreneurial business. We have had great success with our unique retainer model we developed with Edge Legal to support our HR/ER team.",
    author: "Alice Polglase",
    title: "GM Risk & Sustainability",
  },
  {
    image:
      "https://media-exp1.licdn.com/dms/image/C560BAQHYjAY7vUSKng/company-logo_200_200/0/1651713486954?e=2147483647&v=beta&t=BTycgAYzrIDYnxIbiJx0suaYq95bhKoE8yKApFT4_pk",
    company: "Cottee Parker Architects",
    quote:
      "The Crucial Conversations training has transformed the way performance reviews are conducted at Cottee Parker and has triggered a monumental, positive shift towards feedback that is iterative, continuous and effective.",
    author: "Kirrily Murphy",
    title: "General Manager",
  },
];

const Testimonials = () => {
  let [currentTest, setCurrentTest] = useState(0);

  const totalTest = testimonials.length;

  const nextTest = () => {
    if (currentTest === totalTest - 1) {
      setCurrentTest(0);
    } else {
      setCurrentTest(currentTest + 1);
    }
  };

  const prevTest = () => {
    if (currentTest === 0) {
      setCurrentTest(totalTest - 1);
    } else {
      setCurrentTest(currentTest - 1);
    }
  };

  return (
    <section className="py-12 bg-gray-50 overflow-hidden md:py-20 lg:py-24">
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <svg
          className="absolute top-full right-full transform translate-x-1/3 -translate-y-1/4 lg:translate-x-1/2 xl:-translate-y-1/2"
          width={404}
          height={404}
          fill="none"
          viewBox="0 0 404 404"
          role="img"
          aria-labelledby="svg-workcation"
        >
          <title id="svg-workcation">{testimonials[currentTest].company}</title>
          <defs>
            <pattern
              id="ad119f34-7694-4c31-947f-5c9d249b21f3"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={404}
            fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)"
          />
        </svg>

        <p className="text-center text-base font-semibold uppercase text-gray-600 tracking-wider mb-12">
          what our clients have to say about us
        </p>

        <div className="relative">
          <div className="flex items-center justify-center h-24">
            <img
              className="max-h-20 animation-bounce"
              src={testimonials[currentTest].image}
              alt={testimonials[currentTest].company}
            />
          </div>

          <blockquote className="mt-10 h-80 lg:h-48">
            <div className="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900 transition">
              <p>&ldquo;{testimonials[currentTest].quote}&rdquo;</p>
            </div>
            <footer className="mt-8">
              <div className="md:flex md:items-center md:justify-center">
                {/* <div className="md:flex-shrink-0">
                  <img
                    className="mx-auto h-10 w-10 rounded-full"
                    src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                </div> */}
                <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                  <div className="text-base font-medium text-gray-900">
                    {testimonials[currentTest].author}
                  </div>

                  <svg
                    className="hidden md:block mx-1 h-5 w-5 text-e-blue"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M11 0h3L9 20H6l5-20z" />
                  </svg>

                  <div className="text-base font-medium text-gray-500">
                    {testimonials[currentTest].title}
                  </div>
                </div>
              </div>
            </footer>
          </blockquote>

          <div className="flex justify-end pt-12 gap-2">
            <button
              type="button"
              aria-label= "previous testimonial"
              className="inline-flex items-center p-2 border border-transparent shadow-sm text-white bg-e-blue hover:bg-e-dark-blue transition focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={prevTest}
            >
              <ArrowSmLeftIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <button
              type="button"
              aria-label="next testimonial"
              className="inline-flex items-center p-2 border border-transparent shadow-sm text-white bg-e-blue hover:bg-e-dark-blue transition focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={nextTest}
            >
              <ArrowSmRightIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
