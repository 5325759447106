import React from "react";
import { Link } from "gatsby";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const query = graphql`
  {
    allContentfulResource(
      sort: { fields: createdAt, order: DESC }
      filter: { featured: { eq: true } }
      limit: 3
    ) {
      nodes {
        id
        title
        category {
          id
          name
        }
        image {
          gatsbyImageData
        }
        description {
          description
        }
      }
    }
  }
`;

const Featured = () => {
  const data = useStaticQuery(query);
  const articles = data.allContentfulResource.nodes;

  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-col">
          <div className="h-1 bg-gray-200  overflow-hidden">
            <div className="w-24 h-full bg-e-blue"></div>
          </div>
          <div className="flex flex-wrap sm:flex-row flex-col py-6 mb-12">
            <h2 className="sm:w-2/5 text-gray-900 font-medium title-font text-2xl mb-2 sm:mb-0">
              Featured Articles
            </h2>
            <p className="sm:w-3/5 leading-relaxed text-base sm:pl-10 pl-0">
              Explore our archive of "Tips & Trends" that provide actionable steps and real-world commentary on a series of topical issues in the world of employment and safety law. 
            </p>
          </div>
        </div>
        <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
          {articles.map((article) => {
            return (
              <Link
                key= {article.id}
                to={`/${article.title
                  .toLowerCase()
                  .replaceAll(" ", "-")
                  .replaceAll(":", "")
                  .replace("'", "-")}`}
                className="p-4 md:w-1/3  mb-6 relative hover:bg-gray-100 transition"
              >
                <div>
                  <div className="-lg h-64 overflow-hidden hover:opacity-90 transition">
                    <GatsbyImage
                      alt="content"
                      className="object-cover object-center h-full w-full"
                      image={article.image.gatsbyImageData}
                    />
                  </div>
                </div>
                <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1 mt-6">
                  {article.date}
                </h2>
                <h2 className="text-xl font-medium title-font text-gray-900 mt-5 h-20">
                  {article.title}
                </h2>
                <p className="text-base leading-relaxed mt-2 mb-3 h-20">
                  {`${article.description.description.slice(0, 120)}...`}
                </p>
                <svg
                  width="350"
                  height="44"
                  viewBox="0 0 350 44"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="absolute"
                >
                  <path d="M1 1H350" stroke="#1D71B8" strokeLinecap="round" />
                  <path
                    d="M134.902 43L163.728 0.999992"
                    stroke="#1D71B8"
                    strokeLinecap="round"
                  />
                </svg>
                <button
                  className="text-e-blue inline-flex items-center mt-3 hover:underline cursor-pointer relative z-10"
                >
                  Read More
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-4 h-4 ml-2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </button>
              </Link>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Featured;
