import React from 'react'
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'

import {Link} from 'gatsby'
import {AnchorLink} from 'gatsby-plugin-anchor-links'
import Newsletter from '../Newsletter'
import { StaticImage} from 'gatsby-plugin-image'


export default function Example() {

  return (
    <section>
      <div className="relative bg-white overflow-hidden">
      <div className="max-w-7xl mx-auto">
        <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <svg
            className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>

          <Popover>
            <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
              <nav className="relative flex items-center justify-between sm:h-10 lg:justify-start" aria-label="Global">
              </nav>
            </div>

            <Transition
              as={Fragment}
              enter="duration-150 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
            </Transition>
          </Popover>

          <main className="mt-24 md:mt-24 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="sm:text-center lg:text-left">
              <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block xl:inline">Relationships / Respect /</span>{' '}
                <span className="block text-e-blue xl:inline">Results</span>
              </h1>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
              We are Employment & Safety law specialists helping organisations deliver a ‘results with respect’ approach to their workplaces – not driven by process or fear of claims.
              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <div className=" shadow">
                  <AnchorLink
                    to= "/#services"
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium  text-white bg-e-blue hover:bg-e-blue md:py-4 md:text-lg md:px-10"
                  >
                    What we do
                  </AnchorLink>
                </div>
                <div className="mt-3 sm:mt-0 sm:ml-3">
                  <AnchorLink
                    to= "/#contact"
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium  text-e-blue bg-e-light-grey hover:bg-gray-100 md:py-4 md:text-lg md:px-10"
                  >
                    Get in touch
                  </AnchorLink>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <StaticImage
          className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
          src="https://images.ctfassets.net/agskyiykp2ye/1vsmBBbSthxuLT2X15h4zH/6457e7392695e60355390430caf8d863/AdobeStock_311371157.jpeg"
          alt=""
        />
      </div>
    </div>
    <Newsletter/>
    </section>
  )
}