import React from "react";
import {Link} from 'gatsby'
import { CheckIcon, ThumbUpIcon, UserIcon } from "@heroicons/react/solid";

const timeline = [
  {
    id: 1,
    content:
      "Understanding people is understanding business. We believe through self-awareness we can have a growth mindset and communicate effectively",
    target: "Self-Awareness",
    href: "#",
    date: "Sep 20",
    datetime: "2020-09-20",
    icon: UserIcon,
    iconBackground: "bg-e-blue",
  },
  {
    id: 2,
    content:
      "Understanding and anticipating issues so we focus on needs and not answering questions",
    target: "Being Helpful",
    href: "#",
    date: "Sep 22",
    datetime: "2020-09-22",
    icon: ThumbUpIcon,
    iconBackground: "bg-e-dark-blue",
  },
  {
    id: 3,
    content:
      "Always asking what we could have done better or differently to grow and be accountable",
    target: "Own It!",
    href: "#",
    date: "Sep 28",
    datetime: "2020-09-28",
    icon: CheckIcon,
    iconBackground: "bg-e-yellow",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Approach = () => {
  return (
    <div className=" bg-white flow-root pt-12 pb-12 lg:pb-24" id="approach">
      <p className="max-w-7xl mx-auto mt-2 pb-4 px-4 text-3xl leading-8 font-extrabold tracking-tight text-gray-700 sm:text-4xl">
        Why Choose Us?
      </p>
      <p className="max-w-7xl mx-auto mt-2 px-4 pb-6 text-xl leading-8 font-medium tracking-tight text-gray-500">
        We show you how to manage people by building effective{" "}
        <strong>Relationships</strong>. This creates trust and{" "}
        <strong>Respect</strong> so you can have effective crucial
        conversations. Only then will you achieve <strong>Results</strong> to
        retain talent or respectfully remove employees – not driven by process
        or fear of claims.
      </p>
      <p className="max-w-7xl mx-auto mt-2 pb-12 px-4 text-xl leading-8 font-medium tracking-tight text-gray-500">
        We value <strong>Self-Awareness</strong>, <strong>Being Helpful</strong>{" "}
        and to <strong>Own It</strong> in working with each other and our
        clients
      </p>
      <ul
        className="hidden xl:flex flex justify-between max-w-7xl mx-auto px-8"
      >
        {timeline.map((event, eventIdx) => (
          <li key={event.id}>
            <div className="relative pb-8">
              {eventIdx !== timeline.length - 1 ? (
                <span
                  className="absolute top-4 border-t border-e-blue border-dashed w-[450px]"
                  aria-hidden="true"
                />
              ) : null}
              <div className="relative flex space-x-3 flex-col">
                <div>
                  <span
                    className={classNames(
                      event.iconBackground,
                      "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                    )}
                  >
                    <event.icon
                      className="h-5 w-5 text-white"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                  <div>
                    <h2 className="text-lg text-gray-500 font-semibold">
                      {event.target}
                    </h2>
                    <p className="text-sm text-gray-500 max-w-[325px]">
                      {event.content}
                    </p>
                  </div>
                  <div className="text-right text-sm whitespace-nowrap text-gray-500"></div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
      <Link
        to="/articles"
        className=" px-4 text-e-blue inline-flex items-center mt-3 mb-8 hover:underline cursor-pointer relative z-10 lg:hidden"
      >
        Read our Articles
        <svg
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          className="w-4 h-4 ml-2"
          viewBox="0 0 24 24"
        >
          <path d="M5 12h14M12 5l7 7-7 7"></path>
        </svg>
      </Link>
    </div>
  );
};

export default Approach;
