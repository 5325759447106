import React from "react";
import Layout from "../components/Layout";

import Hero from "../components/Hero";
import AltHero from "../components/Tailwind/AltHero";
import Services from "../components/Services";
import Featured from "../components/Featured";
import Testimonials from "../components/Testimonials";
import Contact from "../components/Contact";
import Logos from "../components/Logos";
import SEO from "../components/SEO";
import Approach from "../components/Approach";

const Home = () => {
  return (
    <Layout>
      <SEO
        title="Employment & Safety Law Specialists"
        slug="/"
        image="https://images.ctfassets.net/agskyiykp2ye/1vsmBBbSthxuLT2X15h4zH/6457e7392695e60355390430caf8d863/AdobeStock_311371157.jpeg"
      />
      {/* HERO */}
      {/* <Hero/> */}
      <AltHero />
      {/* SERVICES */}
      <Services />
      <Approach/>
      {/* RESOURCES */}
      <Featured />
      {/* TESTIMONIALS */}
      {/* <Testimonials/> */}
      {/* <Logos /> */}
      <Testimonials/>
      {/* CONTACT */}
      <Contact />
    </Layout>
  );
};

export default Home;
